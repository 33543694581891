.m4t5v4f{max-width:1000px;width:100%;margin:0 auto;}.m4t5v4f .availableFacilitiesRow{margin-bottom:20px;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.m4t5v4f .availableFacilitiesRow .ant-divider{height:15px;}.m4t5v4f .kowake{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.m4t5v4f .kowake > div:first-child{margin-bottom:20px;}.m4t5v4f .ant-divider-horizontal{margin:8px 0px;}.m4t5v4f button{min-width:326px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin:auto;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.b189w2ml{width:100%;margin-bottom:30px;}.b189w2ml .equipment-row{-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;}@media (max-width:768px){.b189w2ml{margin-bottom:0px;}}.b189w2ml ul{margin-bottom:0px;}
.tnodqeb{font-style:normal;font-weight:bold;font-size:16px;line-height:140%;color:#1B1B1B;margin-bottom:20px;}
.sv6pr9p{color:#00a0d2;word-break:break-all;margin-right:10px;}.sv6pr9p span{font-style:normal;font-weight:normal;font-size:14px;line-height:160%;color:#1B1B1B;}
.b4l01al{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}@media (max-width:370px){.b4l01al{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}.b4l01al .availableFacilities{max-width:unset;width:unset;margin-right:20px;}@media (max-width:768px){.b4l01al .availableFacilities{width:100%;margin-bottom:10px;}}.b4l01al .unsetMaxWidth{max-width:unset;}
@media (max-width:768px){.d6oag1l{margin-top:20px !important;margin-bottom:20px !important;}}
@media (min-width:768px){.n10mb2ar{margin-top:0 !important;margin-bottom:0 !important;}}
.b12sh1f0{font-style:normal;font-weight:bold;margin-bottom:0px;font-size:16px;line-height:140%;color:#1B1B1B;max-width:149px;width:100%;min-width:149px;}
.b1dhveri{white-space:break-spaces;width:auto;}
.bo3d81z ul{padding-left:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;}@media (min-width:900px){.bo3d81z{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;grid-template-columns:1fr 1fr;}}.bo3d81z li{width:186px;max-width:186px;padding-right:10px;}
